import React from "react";
import {
  WorkFlowCard,
  WorkFlowImage,
  WorkFlowCardTextContainer,
  WorkFlowCardSpan,
  WorkFlowCardSubHeading,
  WorkFlowCardParagraph,
  WorkFlowImageContainer,
} from "./style/WorkFlowUpdatedCard.style";
import GradientHeading from "../../SharedComponents/GradientHeading/GradientHeading";
import SoftwareSection from "./SoftwareSection";

const WorkFlowUpdatedCard = ({
  imageURL,
  heading,
  spanText,
  explainOne,
  num,
  altText,
}) => {
  return (
    <WorkFlowCard num={num % 2 === 1 ? "row" : "row-reverse"}>
      <WorkFlowImageContainer>
        <WorkFlowImage src={imageURL} alt={altText} />
      </WorkFlowImageContainer>
      <WorkFlowCardTextContainer>
        <WorkFlowCardSubHeading>{spanText}</WorkFlowCardSubHeading>
        <GradientHeading text={heading} mt={0} mb={2} />
        <WorkFlowCardParagraph>{explainOne}</WorkFlowCardParagraph>
        {num === 1 ? <SoftwareSection /> : null}
      </WorkFlowCardTextContainer>
    </WorkFlowCard>
  );
};

export default WorkFlowUpdatedCard;
