export const CardData = [
  {
    imageURL:
      "https://res.cloudinary.com/dkrjwbr8w/image/upload/v1689972351/Seamless_Inegration_dgebuy.webp",
    alt: "",
    heading: "Seamless Integration",
    spanText: "Effortless Adaptation to Your Workflow",
    explainOne:
      "Works in your current Premiere Pro, Final Cut, and DaVinci Resolve workflow. No disruptions, only enhancements. Don’t waste hours learning new NLE software.",
    key: 1,
    altText: "Premiere Pro, Final Cut, and DaVinci Resolve",
  },
  {
    imageURL:
      "https://res.cloudinary.com/dkrjwbr8w/image/upload/v1689970745/Camera_Types_mtdgnq.webp",
    alt: "",
    heading: "Cinematography AI Analysis",
    spanText: "Edits Just Like You",
    explainOne:
      "Sick of sifting through countless bad takes? Our expertly trained AI separates obviously poor video like shaky, blurry, bright and obstructed video. It also understands cinematic camera equipment types and meta data from each video file.",
    key: 2,
    altText: "Different camera types and their settings",
  },
  {
    imageURL:
      "https://res.cloudinary.com/dqwepttg2/image/upload/v1701248557/premiere_sjrmkz.webp",
    alt: "",
    heading: "You Control The Timeline",
    spanText: "AI Sequence Assembly",
    explainOne:
      "Never miss a shot. See exactly what the AI outputted and modify as you please. Choose the settings that work best for your editing style. Continue iterating with the AI. Instantly generate new drafts with your feedback - Your Ultimate Creative Control.",
    key: 3,
    altText: "SpingleAI plugin in premiere pro",
  },
];
