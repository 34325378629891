import CallToAction from "./components/App/CallToAction/CallToAction";
import Footer from "./components/SharedComponents/Footer/Footer";
import NavBar from './components/SharedComponents/nav/NavBar'
import '../src/index.css'
import HomeFooter from "./components/SharedComponents/Footer/Home/HomeFooter";
import Testimonials from "./components/App/Testimonials/Testimonials";
import WorkFlowUpdated from "./components/App/WorkFlowUpdated/WorkFlowUpdated";
import MacSection from "./components/App/MacSection/MacSection";
import SubFeatures from "./components/App/SubFeatures/SubFeatures";
import SiteIntro from "./components/App/SiteIntro/SiteIntro";
import TextReveal from "./components/App/TextReveal/TextReveal";
import PreFooter from "./components/App/PreFooter/PreFooter";


function App() {
  return (
    <>
      <SiteIntro/>
      <NavBar/>
      <CallToAction />
      {/* <TextReveal/> */}
      <SubFeatures />
      <WorkFlowUpdated />
      <MacSection />
      <PreFooter />
      {/* <Testimonials /> */}
      {/* <MeetSpingle/> */}
      <Footer element={<HomeFooter/>}/>
    </>
  );
}

export default App;
