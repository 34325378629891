import styled from "styled-components";
import {
  StyledContainer,
  StyledParagraph,
} from "../../CallToAction/styles/CTA.styled";
import { media } from "../../../../style/media";

const WorkFlowContainer = styled(StyledContainer)`
  margin-top: 0;

  @media ${media.laptopLarge} {
    margin-top: 6rem;
  }

  @media ${media.tablet} {
    align-items: flex-start;
    margin-top: 0;
  }

  @media ${media.mobile} {
    padding-bottom: 1rem;
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const WorkFlowContainerContainer = styled.div`
  max-width: 1520px;
  margin: 0 auto;
  background-color: #fbfbff;
  border-radius: 3% 3% 10% 10% / 2% 2% 0% 0%;
  padding-bottom: 5rem;

  @media ${media.laptop} {
    border-radius: 0;
    padding-bottom: 1rem;
  }
`;

const WorkFlowSubText = styled(StyledParagraph)`
  color: #2e2e2e;
  width: 90%;
  margin-bottom: 5rem;
  font-family: Graphik-400;
  line-height: 1.7rem;
  margin-right: 0;

  @media ${media.laptopLarge} {
    font-size: 1.1rem;
  }

  @media ${media.laptop} {
    font-size: 1rem;
    width: 100%;
    line-height: 1.4rem;
  }

  @media ${media.tablet} {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 3rem;
    font-size: 0.8rem;
  }

  @media ${media.mobile} {
    margin-bottom: 1rem;
  }
`;

export { WorkFlowContainer, WorkFlowSubText, WorkFlowContainerContainer };
