export const socialImages = [
  {
    name: "Cineverse",
    url: "https://res.cloudinary.com/dkrjwbr8w/image/upload/v1682639999/CleancutAssets/cineverseLogo_mb4jm9.png",
  },
  {
    name: "4YE",
    url: "https://4ye.ca/cdn/shop/files/4YE_logo_032f1e22-5995-4dbf-b035-6616ab25c355_x350.png?v=1651084732",
  },
  {
    name: "YouTwoTV",
    url: "https://images.squarespace-cdn.com/content/v1/57f90215e4fcb5e6ab1de29f/1612816259523-1KZDKJZDKKFMDXD33655/YouTwo__TwoToneLogo.png?format=1500w",
  },
  {
    name: "PrismMediaLab",
    url: "https://static.wixstatic.com/media/f813a7_42810d2c781b4108bffa271bb5241e9f~mv2.png/v1/fill/w_323,h_120,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/f813a7_42810d2c781b4108bffa271bb5241e9f~mv2.png",
  },
];