export const jobs = [
    // {
    //     title: 'Computer Vision Engineer',
    //     href: '#'
    // },
    // {
    //     title: 'AI Engineer',
    //     href: '#'
    // },
    // {
    //     title: 'Product Manager',
    //     href: '#'
    // },
]