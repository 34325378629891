import styled from "styled-components";

const TextRevealContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #000000;
  backdrop-filter: blur(25px);
  overflow: hidden;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  scroll-behavior: smooth;
`;

const TextRevealText = styled.p`
  font-family: Graphik-700;
  font-size: 5rem;
  color: #f0f0f0;
  line-height: 6rem;
  margin: 4rem 0;
  span {
    opacity: 0.1;
  }
`;

const TextRevealSpan = styled.span`
  font-family: Graphik-700;
  font-size: 5rem;
  color: #f0f0f0;
  line-height: 6rem;
  opacity: .1;
`;

export { TextRevealContainer, TextRevealText, TextRevealSpan };
