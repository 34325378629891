import React from "react";
import {
  FooterContainer,
  FooterContentContainer,
  FooterText,
  FooterLogo,
  FooterContainerContainer,
  FooterLink,
} from "./styles/Footer.styles";

const Footer = ({element}) => {
  //The element prop takes in the react component and renders it with another FooterContentContainer
  return (
    <FooterContainerContainer>
      <FooterContainer>
        <FooterContentContainer left={true}>
          <FooterLogo>Spingle.ai</FooterLogo>
          <FooterText>© 2023 Cleancut Inc. All rights reserved.</FooterText>
          <FooterLink target="_blank" href="https://www.notion.so/cleancutai/Privacy-Policy-for-Cleancut-ai-Inc-883af18c75c94b5897e4f80f6da9b91d">Privacy Policy</FooterLink>
        </FooterContentContainer>
        {element}
      </FooterContainer>
    </FooterContainerContainer>
  );
};

export default Footer;
