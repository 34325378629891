import styled from "styled-components";

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #ff9385, #ffbf85);
`;

export const SuccessModal = styled.div`
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.2);
  width: 70%;
  padding: 1rem;
`;

export const SuccessContent = styled.div`
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
`;

export const SuccessTitle = styled.h1`
  font-family: Graphik-700;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  text-align: center;
`;

export const SuccessText = styled.p`
  font-size: 1rem;
  margin: 0.25rem 0 0.3rem 0;
  font-family: "Graphik-300";
  text-align: center;
`;

export const SuccessImage = styled.img`
  height: 150px;
`;
