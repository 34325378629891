import styled from "styled-components";
import {
  StyledContainer,
  StyledHeading,
  StyledParagraph,
} from "../../CallToAction/styles/CTA.styled";
import { media } from "../../../../style/media";

const SocialBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  margin-bottom: 2rem;
  @media ${media.tablet} {
    padding-top: 2rem;
  }
`;

const SocialBannerHeading = styled(StyledHeading)`
  text-align: center;
  font-size: 2.5rem;
  line-height: 2rem;

  @media ${media.tablet} {
    font-size: 1.7rem;
    width: 100%;
  }

  @media ${media.mobile} {
    font-size: 1.3rem;
  }
`;

const SocialBannerText = styled(StyledParagraph)`
  font-family: Graphik-400;
  text-align: center;
  font-size: 1.2rem;
  margin: 1rem 0 2rem 0;
  color: #7e7e7e;

  @media ${media.tablet} {
    margin: 2rem 0;
    font-size: 1.1rem;
  }

  @media ${media.tablet} {
    font-size: 0.9rem;
    margin: 1.5rem 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin-bottom: 3rem;
  gap: 8rem;
  justify-content: center;

  @media ${media.laptop} {
    width: 70%;
    gap: 5rem;
  }

  @media ${media.tablet} {
    margin-bottom: 3rem;
    width: 80%;
    gap: 2rem;
  }

  @media ${media.mobile} {
    margin-bottom: 1rem;
    gap: 1.25rem;
  }

`;
const SocialImages = styled.img`
  height: 55px;

  @media ${media.laptopLarge} {
    height: 45px;
  }

  @media ${media.tablet} {
    height: 35px;
  }

  @media ${media.mobile} {
    height: 25px;
  }
`;

export {
  SocialBannerContainer,
  SocialBannerHeading,
  SocialBannerText,
  ImageContainer,
  SocialImages,
};
