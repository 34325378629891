import React from "react";
import { WorkFlowData } from "./WorkFlowUpdatedData";
import { CardData } from "./WorkFlowUpdatedData";
import {
  ContainerContainer,
  ContainerWrapper,
} from "../MeetSpingle/style/MeetSpingle.style";
import {
  WorkFlowContainer,
  WorkFlowContainerContainer,
  WorkFlowSubText,
} from "./style/WorkFlowUpdated.style";
import GradientHeading from "../../SharedComponents/GradientHeading/GradientHeading";
import WorkFlowUpdatedCard from "./WorkFlowUpdatedCard";
import SocialBanner from "../SocialBanner/SocialBanner";
import { WorkFlowCardSubHeading } from "./style/WorkFlowUpdatedCard.style";

const WorkFlowUpdated = () => {
  return (
    <ContainerWrapper>
      <WorkFlowContainerContainer>
        <SocialBanner />
        <ContainerContainer>
          <WorkFlowContainer>
            <WorkFlowCardSubHeading>
              Why Choose Spingle.ai?
            </WorkFlowCardSubHeading>
            <GradientHeading
              text={"Work At The Speed Of Creativity"}
              mb={0}
              mt={0}
            />
            <WorkFlowSubText>
              Our AI focuses on filtering out the bad parts of your footage, we
              leave the creative parts to you. This is your story - we just
              want to give you superpowers to get there faster. Spend more time
              on story - less time on the tedious parts.
            </WorkFlowSubText>
            {CardData.map((card, index) => {
              return (
                <WorkFlowUpdatedCard
                  imageURL={card.imageURL}
                  heading={card.heading}
                  spanText={card.spanText}
                  numOne={card.numOne}
                  explainOne={card.explainOne}
                  numTwo={card.numTwo}
                  explainTwo={card.explainTwo}
                  key={index}
                  num={card.key}
                />
              );
            })}
          </WorkFlowContainer>
        </ContainerContainer>
      </WorkFlowContainerContainer>
    </ContainerWrapper>
  );
};

export default WorkFlowUpdated;
