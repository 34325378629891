import React from "react";
import {
  HeadingSpan,
  SubFeaturesContainer,
  SubFeaturesContentContainer,
  SubFeaturesHeading,
  SubFeaturesImage,
  SubFeaturesInformation,
  SubFeaturesText,
} from "./styles/SubFeatures.style";
import { information } from "./SubFeaturesInfo";
import SubFeaturesCard from "./SubFeaturesCard";

const SubFeatures = () => {
  return (
    <SubFeaturesContainer>
      <SubFeaturesContentContainer>
        <SubFeaturesImage
          src="https://res.cloudinary.com/dkrjwbr8w/image/upload/v1688797151/CleancutAssets/purpleCamera_xzwyqz.webp"
          alt="Camera shot in progress with Spingle purple filter"
        />
        <SubFeaturesInformation>
          <HeadingSpan>Save precious time</HeadingSpan>
          <SubFeaturesHeading>Turn Weeks Into Days</SubFeaturesHeading>
          <SubFeaturesText>Built by editors, for editors.</SubFeaturesText>
          <SubFeaturesText>
            The most important piece is the story, but it often gets left behind
            with impossible deadlines. Stop rushing edits at the cost of story
            and creativity. Join the future of editing.
          </SubFeaturesText>
          {information.map((card, index) => {
            return <SubFeaturesCard text={card.text} key={index} />;
          })}
        </SubFeaturesInformation>
      </SubFeaturesContentContainer>
    </SubFeaturesContainer>
  );
};

export default SubFeatures;
