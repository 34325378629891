import React, { useEffect, useRef } from "react";

const SiteIntro = () => {
  //Use react refs to target the necessary elements
  const introRef = useRef(null);
  const logoSpansRef = useRef([]);

  useEffect(() => {
    //Target the necessary elements
    const introElement = introRef.current;
    const logoSpans = logoSpansRef.current;

    //Animating in the text for the logo, execute immediately
    //Add 400ms before executing the active class to all the span elements
    const animateIn = setTimeout(() => {
      logoSpans.forEach((span, index) => {
        setTimeout(() => {
          span.classList.add("active");
        }, (index + 1) * 400);
      });

      //Animating out the text for the logo after 2s
      //With 50ms remove each span elemeent
      const animateOut = setTimeout(() => {
        logoSpans.forEach((span, index) => {
          setTimeout(() => {
            span.classList.remove("active");
            span.classList.add("fade");
          }, (index + 1) * 50);
        });
      }, 2500);

      //Animating up the background after 2.3s
      const backgroundUp = setTimeout(() => {
        introElement.style.top = "-100vh";
      }, 3100);

      //Return cleantimeout
      return () => {
        clearTimeout(animateIn);
        clearTimeout(animateOut);
        clearTimeout(backgroundUp);
      };
    }, 0); // Delay the effect to allow DOM rendering
  }, []);

  return (
    <div ref={introRef} className="intro">
      <h1 className="logo-header">
        <span ref={(span) => logoSpansRef.current.push(span)} className="logo">
          Spin
        </span>
        <span ref={(span) => logoSpansRef.current.push(span)} className="logo">
          gle
        </span>
        <span ref={(span) => logoSpansRef.current.push(span)} className="logo">
          .
        </span>
        <span ref={(span) => logoSpansRef.current.push(span)} className="logo">
          ai
        </span>
      </h1>
    </div>
  );
};

export default SiteIntro;
