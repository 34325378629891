import styled from "styled-components";
import { media } from "../../../../style/media";

const HeadingText = styled.h2`
  font-family: Graphik-500;
  margin-bottom: ${props => `${props.mb}rem` || '0rem'};
  margin-top: ${props => `${props.mt}rem` || '1rem'};
  font-size: 3.5rem;
  background: rgb(115, 71, 231);
  background-image: linear-gradient(
    90deg,
    rgba(64, 36, 141, 1) 0%,
    rgba(103, 62, 214, 1) 100%
  );
  background-clip: text;
  text-align: ${props => `${props.ta}` || `right`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${media.laptopLarge} {
    font-size: 2.5rem
  }

  @media ${media.laptop} {
    font-size: 2rem;
    margin-bottom: 1rem
  }

  @media ${media.tablet} {
    margin-bottom: .5rem;
    font-size: 1.7rem;
  }

  @media ${media.tablet} {
    font-size: 1.4rem
  }

`;

export { HeadingText };
