import React from "react";
import {
  MacSectionContainer,
  MacSectionContainerContainer,
  MacSectionImg,
  MacSectionText,
  MacSectionTitleSubText,
} from "./style/MacSection.style";
import { ContainerContainer } from "../MeetSpingle/style/MeetSpingle.style";
import GradientHeading from "../../SharedComponents/GradientHeading/GradientHeading";
import { macCardData } from "./MacSectionCardData";
import WorkFlowUpdatedCard from "../WorkFlowUpdated/WorkFlowUpdatedCard";
import { WorkFlowContainer } from "../WorkFlowUpdated/style/WorkFlowUpdated.style";

const MacSection = () => {
  return (
    <MacSectionContainerContainer>
      <ContainerContainer>
        <GradientHeading text={"Future Updates & Features"} ta={"center"} />
        <MacSectionTitleSubText>
          With your direction, we take the best parts and then build sequences.
        </MacSectionTitleSubText>
        <WorkFlowContainer>
          {macCardData.map((card, index) => {
            return (
              <WorkFlowUpdatedCard
                imageURL={card.imageURL}
                heading={card.heading}
                spanText={card.spanText}
                numOne={card.numOne}
                explainOne={card.explainOne}
                numTwo={card.numTwo}
                explainTwo={card.explainTwo}
                key={index}
                num={card.key}
              />
            );
          })}
        </WorkFlowContainer>
        <MacSectionContainer>
          <MacSectionImg
            src="https://res.cloudinary.com/dqwepttg2/image/upload/v1701246081/group_styles_dhfanf.webp"
            alt="Pre-set video editing templates"
          />
          {/* <GradientHeading
            text={
              "Start closer to the finish line with customizable templates by industry experts"
            }
            ta={"center"}
          />
          <MacSectionText>
            Spingle AI can train on past projects to create your own Custom AI
            Templates for any style you want.
          </MacSectionText> */}
        </MacSectionContainer>
      </ContainerContainer>
    </MacSectionContainerContainer>
  );
};

export default MacSection;
