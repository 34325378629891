export const macCardData = [
  {
    imageURL:
      "https://res.cloudinary.com/dkrjwbr8w/image/upload/v1690214777/CleancutAssets/Spingle_Prompt_New_j8xryk.webp",
    alt: "",
    heading: "Instantly Generate Edits",
    spanText: "Creative Momentum",
    explainOne:
      "Use a prompt to build sequences of your clips. Have a dynamic back and forth with your AI, to continue iterating fast and get closer to the finish line.",
    key: 2,
    altText: "SpingleAI with new prompt feature to generate edits",
  },
];

