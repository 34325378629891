import React, { useEffect } from "react";
import confetti from "canvas-confetti";
import {
  SuccessContainer,
  SuccessModal,
  SuccessContent,
  SuccessTitle,
  SuccessImage,
  SuccessText,
} from "../components/Success/styles/Success.style";

const Success = () => {
  useEffect(() => {
    const duration = 7 * 1000;
    const end = Date.now() + duration;

    (function frame() {
      confetti({
        particleCount: 3,
        angle: 60,
        spread: 100,
        origin: { x: 0 },
      });
      confetti({
        particleCount: 3,
        angle: 120,
        spread: 100,
        origin: { x: 1 },
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }, []);

  return (
    <SuccessContainer>
      <SuccessModal>
        <SuccessContent>
          <SuccessTitle>Payment Successful</SuccessTitle>
          <SuccessImage src="https://res.cloudinary.com/dkrjwbr8w/image/upload/v1721164352/undraw_Polaroid_re_481f_bfxvdf.png" />
          <SuccessText>Welcome to the future of video editing.</SuccessText>
          <SuccessText>
            You’re ready to unlock a world of innovative tools and seamless
            editing experiences. Your journey to creating stunning videos begins
            now.
          </SuccessText>
        </SuccessContent>
      </SuccessModal>
    </SuccessContainer>
  );
};

export default Success;
