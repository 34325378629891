import styled from "styled-components";
import { StyledParagraph } from "../../CallToAction/styles/CTA.styled";
import { media } from "../../../../style/media";

const WorkFlowCard = styled.div`
  display: flex;
  flex-direction: ${(props) => props.num || "row"};
  justify-content: space-around;
  margin: 2rem 0 5rem 0;
  align-items: center;


  @media ${media.laptop} {
    justify-content: space-evenly;
    gap: 3rem;
    align-items: center;
  }

  @media ${media.tablet} {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 3rem;
    width: -webkit-fill-available;
  }

`;

const WorkFlowImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -1.5rem;
    background: linear-gradient(
      var(--gradient-angle),
      #1d183b,
      #261b68,
      #0c0536,
      #1d183b,
      #261b68
    );
    border-radius: 1.875rem;
    padding: 1rem;
    animation: rotation 20s linear infinite;
    z-index: 2;
    
    @media ${media.laptopLarge} {
      inset: -1.25rem;
    }

    @media ${media.laptop} {
      inset: -1rem;
    }

    @media ${media.mobile} {
      inset: -.75rem;
    }
  }

  &::after {
    /* filter: blur(3.5rem); */
  }

  @media ${media.tablet} {
    width: fit-content;
  }
`;

const WorkFlowImage = styled.img`
  height: auto;
  width: 450px;
  /* border-radius: 1.875rem; */
  position: relative;
  z-index: 3;
  // top: 50%;
  // transform: translateY(-50%);

  @media ${media.laptopLarge} {
    // height: 390px;
    width: 390px;
  }

  @media ${media.laptop} {
    // height: 300px;
    width: 300px;
  }

  @media ${media.laptop} {
    // height: 400px;
    width: 400px;
  }

  @media screen and (max-width: 525px) {
    // height: 300px;
    width: 300px;
    transform: translateY(-0%);
  }

  @media ${media.mobile} {
    // height: 250px;
    width: 250px;
  }

`;

const WorkFlowCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 470px;

  @media ${media.laptopLarge} {
    width: 400px;
  }

  @media ${media.tablet} {
    width: 100%;
  }

`;
const WorkFlowCardSpan = styled.span`
  font-family: Graphik-500;
  color: #7246e8;
`;

const WorkFlowCardSubHeading = styled.p`
  font-family: Graphik-600;
  color: #737192;
`;

const WorkFlowCardParagraph = styled.p`
  color: #2E2E2E;
  font-family: Graphik-400;
  width: 70%;
  line-height: 1.5rem;

  @media ${media.laptopLarge} {
    width: 90%;
  }

  @media ${media.laptop} {
    font-size: 1rem;
    line-height: 1.3rem;
  }

`;

export {
  WorkFlowCard,
  WorkFlowImage,
  WorkFlowCardTextContainer,
  WorkFlowCardSpan,
  WorkFlowCardSubHeading,
  WorkFlowCardParagraph,
  WorkFlowImageContainer,
};
