import styled from "styled-components";
import {
  StyledContainer,
  StyledParagraph,
} from "../../CallToAction/styles/CTA.styled";
import { media } from "../../../../style/media";

const MacSectionContainer = styled(StyledContainer)`
  align-items: center;
  margin-bottom: 2rem;
`;

const MacSectionImg = styled.img`
  width: 80%;
  margin-top: 8rem;

  @media ${media.laptopLarge} {
    margin-top: 6rem;
  }

  @media ${media.laptop} {
    margin-top: 4rem;
  }

  @media ${media.tablet} {
    margin-top: 3rem;
  }

  @media ${media.mobile} {
    margin-top: 2rem;
  }
`;

const MacSectionText = styled(StyledParagraph)`
  color: #DCCFFF;
  margin: 0;
`;
const MacSectionContainerContainer = styled.div`
  max-width: 1520px;
  margin: 0 auto;
  background: linear-gradient(180deg, #000 19.55%, #2B0D4D 100%);
  backdrop-filter: blur(25px);
  border-radius: 15% 15% 15% 10% / 12% 12% 15% 0%;
  position: relative;
  top: -150px;
  z-index: 2;
  padding-top: 10rem;
  padding-bottom: 10rem;

  @media ${media.laptop} {
    position: initial;
    border-radius: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

`;

const MacSectionTitleSubText = styled.p`
  text-align: center;
  color: #737192;
  font-family: Graphik-600;
  margin-bottom: 2rem;

  @media ${media.tablet} {
    margin-bottom: 0;
    margin: 0 1.5rem 0rem 1.5rem;
  }
`

export {
  MacSectionContainer,
  MacSectionImg,
  MacSectionText,
  MacSectionContainerContainer,
  MacSectionTitleSubText
};
