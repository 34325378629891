import styled from "styled-components";
import { StyledParagraph } from "../../CallToAction/styles/CTA.styled";
import { media } from "../../../../style/media";

const SubFeaturesContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    180deg,
    rgba(40, 12, 66, 0.8) 0%,
    rgba(73, 8, 133, 0.8) 54.69%,
    rgba(29, 3, 33, 0.8) 100%
  );
  backdrop-filter: blur(25px);
  overflow: hidden;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const SubFeaturesImage = styled.img`
  height: 750px;
  position: relative;

  @media ${media.laptopLarge} {
    height: 650px;
  }

  @media ${media.laptop} {
    left: -100px;
    height: 600px;
  }

  @media ${media.tablet} {
    display: none;
  }
`;

const SubFeaturesInformation = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ${media.laptop} {
    flex-grow: 2;
  }
`;

const SubFeaturesHeading = styled.h2`
  font-family: Graphik-600;
  font-size: 3.75rem;
  color: #8e63ff;
  width: 90%;

  @media ${media.laptopLarge} {
    font-size: 3rem;
    margin: 1rem 0;
  }

  @media ${media.laptop} {
    font-size: 2.5rem;
    margin: 0.5rem 0;
  }
`;

const HeadingSpan = styled.span`
  font-family: Graphik-600;
  font-size: 1.25rem;
  color: #737192;

  @media ${media.laptop} {
    font-size: 1rem;
  }
`;

const SubFeaturesText = styled(StyledParagraph)`
  color: #eee9ff;
  font-size: 1.25rem;
  margin-right: 0;
  font-family: Graphik-300;
  width: 80%;
  line-height: 1.7rem;

  @media ${media.laptopLarge} {
    font-size: 1.1rem;
  }

  @media ${media.laptop} {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
`;

const SubFeaturesContentContainer = styled.div`
  margin: 7rem 6rem;
  display: flex;
  justify-content: center;
  gap: 5rem;

  @media ${media.laptopLarge} {
    margin: 3rem;
    gap: 3rem;
  }

  @media ${media.laptop} {
    gap: 0;
  }

  @media ${media.tablet} {
    flex-direction: column-reverse;
  }

  @media ${media.mobile} {
    margin: 1.5rem;
  }
`;

export {
  SubFeaturesContainer,
  SubFeaturesImage,
  SubFeaturesInformation,
  SubFeaturesHeading,
  HeadingSpan,
  SubFeaturesText,
  SubFeaturesContentContainer,
};
