import styled from "styled-components";
import { media } from "../../../../style/media";
import { motion } from "framer-motion";
//This is the navigation container styling
//The Nav needs to float over the content and be sticky as the user scrolls through the content
const NavContainer = styled(motion.div)`
  position: sticky;
  z-index: 100;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;

  @media ${media.tablet} {
    background-color: black;
  }
`;
const Nav = styled.nav`
  padding: 0rem 0rem 0rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 5;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1440px;
  @media ${media.tablet} {
    padding: 1rem;
  }

  @media ${media.mobile} {
    padding: 0.75rem;
  }
`;

const BorderBottom = styled.div`
  height: 2px;
  background-color: #e5e3ff;
  width: 100%;
  margin: 0 auto;
`;

const Hamburger = styled.div`
  flex-direction: column;
  display: none;
  cursor: pointer;
  color: black;

  span {
    border: 1px solid #fff;
    width: 25px;
    margin-bottom: 4px;
    border-radius: 5px;
  }

  @media ${media.tablet} {
    display: flex;
    position: relative;
    right: 10px;
  }
`;

const MenuLink = styled.a`
  font-family: Graphik-500;
  text-decoration: none;
  color: #03004e;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 1.7rem 1.3rem;
  display: flex;
  margin-right: 3rem;
  cursor: pointer;
  align-items: center;
  border-bottom: solid white;

  &:hover {
    border-bottom: solid black;
  }

  @media ${media.tablet} {
    margin-right: 0;
    width: 100%;
    justify-content: center;
    &:hover {
      border-bottom: none;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex-grow: 1;
  /* margin-right: 2rem; */
  font-family: Graphik;

  @media ${media.laptopLarge} {
    /* width: 50%; */
  }

  @media ${media.laptop} {
    /* width: 70%; */
  }

  @media ${media.tablet} {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0")};
    margin-right: 0;
  }
`;

const Logo = styled.a``;

const LogoImage = styled.img`
  height: auto;
  width: 150px;
  position: relative;

  @media ${media.tablet} {
    width: 150px;
    left: 20px;
    top: 5px;
  }
`;

const NavBG = styled.div`
  position: absolute;
  top: 0px;
  height: 100px;
  width: 100%;
  background-color: black;

  @media ${media.tablet} {
    display: none;
  }
`;

const LinkTag = styled.p`
  font-family: Graphik-500;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem;
  margin: 0;

  @media ${media.tablet} {
    color: #bcbcbc;
  }
`;

const NavLogoText = styled.p`
  font-family: Logo;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  flex-grow: 1;
  margin: 0;
`;

const NavWebLinksContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  gap: 2.5rem;

  @media ${media.tablet} {
    display: none;
  }
`;

const WebLinks = styled.a`
  font-family: Graphik-500;
  color: #949494;
  text-decoration: none;
  padding: 0.5rem;
  cursor: pointer;

  @media ${media.tablet} {
    color: #bcbcbc;
  }
`;

export {
  Nav,
  Hamburger,
  MenuLink,
  Menu,
  Logo,
  LogoImage,
  NavContainer,
  BorderBottom,
  NavBG,
  LinkTag,
  NavLogoText,
  NavWebLinksContainer,
  WebLinks,
};
