import React from "react";
import styled from "styled-components";
import { SubFeaturesText } from "./styles/SubFeatures.style";
import { StyledParagraph } from "../CallToAction/styles/CTA.styled";
import { media } from "../../../style/media";

const CardContainer = styled.div`
  padding: 2rem;
  border-radius: 1.25rem;
  border: 1px solid #7e39cf;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.2rem;
  margin: 1.3rem 0;

  @media ${media.laptopLarge} {
    width: 70%;
    padding: 1.7rem;
  }

  @media ${media.laptop} {
    width: 90%;
    padding: 1.2rem;
  }
`;

const CardText = styled(StyledParagraph)`
  color: #eee9ff;
  font-size: 1.25rem;
  margin: 0;
  font-family: Graphik-300;
  line-height: 1.9rem;

  @media ${media.laptopLarge} {
    line-height: 1.5rem;
    font-size: 1.1rem;
  }

  @media ${media.laptop} {
    line-height: 1.2rem;
    font-size: .9rem;
  }
`;

const CardIcon = styled.img`
  width: 49px;
  height: 49px;
`;

const SubFeaturesCard = ({ text }) => {
  return (
    <CardContainer>
      <CardIcon src="https://res.cloudinary.com/dkrjwbr8w/image/upload/v1688798670/CleancutAssets/Group_3533_wrmy3s.webp" />
      <CardText>{text}</CardText>
    </CardContainer>
  );
};

export default SubFeaturesCard;
