import styled from "styled-components";
import { media } from "../../../../style/media";
import { StyledParagraph } from "../../CallToAction/styles/CTA.styled";

const PreFooterContainer = styled.div`
  max-width: 1520px;
  margin: 0 auto;
  border-radius: 0rem 0rem 3.125rem 3.125rem;
  background: linear-gradient(180deg, #000 19.55%, #2b0d4d 100%);
  backdrop-filter: blur(25px);
  border-radius: 15% 15% 10% 10% / 15% 15% 0% 0%;
  position: relative;
  top: -275px;
  z-index: 2;
  padding-top: 8rem;
  padding-bottom: 8rem;

  @media ${media.laptop} {
    position: initial;
    border-radius: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;

const PreFooterParagraph = styled(StyledParagraph)`
  padding-bottom: 3rem;
  font-style: Graphik-300;
  color: #dccfff;
  font-size: 1.3rem;
  margin: 0;
  line-height: 1.7rem;
  width: 40%;
  text-align: center;

  @media ${media.laptopLarge} {
    padding-bottom: 2rem;
    font-size: 1.2rem;
    width: 80%;
  }

  @media ${media.laptop} {
    margin: 0;
  }

  @media ${media.tablet} {
    padding-bottom: 1rem;
    font-size: 1rem;
  }

  @media ${media.mobile} {
    font-size: 0.85rem;
    padding-bottom: 0.5rem;
    width: 90%;
  }
`;

const PreFooterTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 8rem;
  
    @media ${media.laptopLarge} {
        padding-top: 6rem;
    }

    @media ${media.laptop} {
        padding-top: 4rem;
    }

    @media ${media.tablet} {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
`

export { PreFooterContainer, PreFooterParagraph, PreFooterTextContainer };
